import React from "react";
import twitter from "../../assets/images/icons/twt.webp";
import fb from "../../assets/images/icons/fb.webp";
import ig from "../../assets/images/icons/ig.webp";
import yt from "../../assets/images/icons/youtube.webp";
import whatsapp from "../../assets/images/icons/wp.png";
import SocialCard from "../reusables/SocialCard";
import { Slide } from "react-reveal";

const Social = () => {
  return (
    <section className=" section-spacing-100">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-head center width-300 margin-auto">
              <h3 className="section-title title">
                Find
                <span className="block">MALIDAD.COM</span> On
              </h3>
            </div>
            <div className="section-body section-spacing-40 no-padding-bottom ">
              <div className="social-container flex">
                <SocialCard
                  link="https://twitter.com/MalidadBooks"
                  icon={twitter}
                  iconAlt="Twitter"
                  text="Daily tweets and weekly threads with ideas, tips and hacks on what matters most to you"
                />

                <SocialCard
                  link="https://facebook.com/MALIDADcom"
                  icon={fb}
                  iconAlt="Facebook"
                  text="Short videos, ideas, tips and hacks on what matters most to you"
                />
                <SocialCard
                  link="https://www.youtube.com/channel/UCXra7mGElBXnerAEwxBgUZQ"
                  icon={yt}
                  iconAlt="Youtube"
                  text="Videos on topics that matters to you to bring the best of every aspect of your life"
                />
                <SocialCard
                  link="https://wa.me/13152742122"
                  icon={whatsapp}
                  iconAlt="WhatsApp"
                  text="24/7 support to answer your questions and inquiries and say hi anyways"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Social;
