import React, { useState } from "react";

const BuyResponsive = () => {
  return (
    <div className="cta-button-container">
      <a
        href="/buy"
        target="_blank"
        rel="noopener noreferrer"
        className="site-main-cta button light-yellow-button dark uppercase bold"
      >
        Order your copy
      </a>
    </div>
  );
};

export default BuyResponsive;
