import React, { useState } from "react";
import { Slide } from "react-reveal";
import cover from "../../assets/images/content/dd-piled.png";
import cover2 from "../../assets/images/content/cover.png";
import BuyModalContents from "../reusables/BuyModalContents";
import Modal from "../reusables/Modal";

const AboutBook = () => {
  const [show, setShow] = useState(false);
  return (
    <section className="section-spacing-100" id="about">
      <div className="about-inner">
        <div className="container">
          <div className="large">
            <div className="section-content-container">
              <Slide top>
                <div className="section-head center width-300 margin-auto">
                  <h3 className="section-title title white">
                    About <br></br>
                    <span>Dad Daughter Book</span>
                  </h3>
                </div>
              </Slide>
              <div className="section-body section-spacing-80 no-padding-bottom">
                <div className="about-container flex">
                  <div className="about-text-side">
                    <h4 className=" italic">
                      <q>
                        A beautiful girl is born. A responsible woman is built
                      </q>{" "}
                      - MALIDAD.COM
                    </h4>
                    <p className="margin-top-10 ">
                      One of the best blessings God can bestow upon you is the
                      gift of a Daughter amongst your children.
                    </p>
                    <p className="margin-top-10 ">
                      All children are a blessing but not equally blessed. A
                      Daughter is sugar. But, such a blessing comes with a
                      responsibility to make a responsible woman out of her,
                      otherwise you are as good as someone who did not get that
                      blessing.
                    </p>
                    <p className="margin-top-10 ">
                      Because a beautiful girl is born and a responsible woman
                      is built. Take a moment and ask yourself: What do you
                      really want for your Daughter? What qualities do you hope
                      she develops and takes into her adult life? How much of
                      your time do you spend intentionally developing those
                      qualities in her? If you are like many Fathers today, you
                      worry that you spend too much time just trying to get
                      through the day and not enough time creating experiences
                      that help your Daughter thrive, both today and in the
                      future.
                    </p>
                    <p className="margin-top-10 ">
                      That is where the Dad Daughter book comes in. It gives
                      plenty answers to life’s many questions and provides ways
                      to correct and prevent a lot of wrongs and troubles in the
                      life of your Daughter whether in your absence or presence.
                    </p>
                    <p className="margin-top-10 ">
                      Dad Daughter book is not just 200 Life Lessons Every
                      Father Must Teach His Daughter, but also 200 ways for a
                      Father to make his Daughter wiser and more responsible in
                      life.
                    </p>
                    <div className="margin-top-10 center bold light-yellow">
                      265 Pages -|- Paperback ISBN: 978-0-6397-1969-6
                    </div>
                    <p className="margin-top-10 grey italic center boldfont">
                      <q>
                        As a parent, I could connect with the wisdom packed in
                        each lesson and what I would desire that my child
                        grasps. As a writer, the plainness and sincerity of the
                        tone used to communicate Dad’s heart to his Daughter,
                        impressed me.{" "}
                      </q>{" "}
                      - <span className="">Brad Odilo</span>
                    </p>
                    <div className="cta-container section-spacing-20">
                      {/*  <a
                        target="_blank"
                        href="https://form.jotform.com/booksmalidadcom/dad-daughter-book-pre-order"
                        className="book-cta button light-yellow-button bold uppercase"
                      >
                        pre-order your copy
                      </a>
                      */}
                      <button
                        onClick={() => setShow(true)}
                        className="book-cta button light-yellow-button bold uppercase"
                      >
                        Order your copy
                      </button>
                      <Modal onClose={() => setShow(false)} show={show}>
                        <BuyModalContents />
                      </Modal>
                    </div>
                  </div>
                  <div className="about-image-side">
                    <img
                      src={cover2}
                      alt="Dad Daughter Book"
                      className="cover2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutBook;
