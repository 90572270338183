import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { heroReviewsCarousel } from "../reusables/heroReviewsCarousel";
import star from "../../assets/images/icons/star.svg";
import right from "../../assets/images/icons/right.svg";
import cover from "../../assets/images/content/cover.png";
import { Slide } from "react-reveal";
import ReviewSlideCard from "../reusables/ReviewSlideCard";
import wft from "../../assets/images/content/wft.png";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import visa from "../../assets/images/pay/visa.svg";
import airtel from "../../assets/images/pay/airtel.svg";
import mtn from "../../assets/images/pay/mtn.svg";
import mastercard from "../../assets/images/pay/mastercard.svg";

const HomeHero = () => {
  return (
    <section className="hero section-spacing-10 no-padding-top">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="hero-contents-container flex">
              <div className="text-side">
                <div className="hero-rating flex">
                  <img src={star} alt="Star" className="icon" />
                  <p className="bold">4.9</p>
                  <p className="bold dark">Reader Reviews</p>
                </div>
                <div className="text-side-body center">
                  <h2 className="hero-title">
                    Dad <br />
                    Daughter Book
                  </h2>
                  <p className="hero-subtitle capitalize">
                    <span className="bold">200 Life Lessons</span> every{" "}
                    <span className="bold">Father</span> must{" "}
                    <span className="bold">Teach</span> his{" "}
                    <span className="bold">Daughter</span>
                  </p>
                </div>
                <div className="text-side-cta flex">
                  <div className="main-cta modal-container">
                    {/* <a
                      target="_blank"
                      href="https://form.jotform.com/booksmalidadcom/dad-daughter-book-pre-order"
                      className="button flex light-yellow-button bold uppercase modal-trigger"
                    >
                      <span className="right-push-4">Pre-order your copy</span>
                      <img src={right} alt="Right Arrow" className="icon" />
                    </a>*/}
                    <p className="left rich-small margin-bottom-10 hero-offer">
                      <span className="block bold uppercase red">
                        RETAIL PRICE:
                      </span>

                      <span className=" block margin-top-10">
                        UGX: <span className=" bold">54,999</span> | USD{" "}
                        <span className=" bold">$15.99</span>
                      </span>
                    </p>
                    <div className="buy-cta">
                      <a
                        href="/buy"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="button flex light-yellow-button bold uppercase modal-trigger"
                      >
                        <span className="right-push-4">Order your copy</span>
                        <img src={right} alt="Right Arrow" className="icon" />
                      </a>
                      <div className="accepted-payments">
                        <p className="rich-small uppercase bold">PAY WITH</p>
                        <div className="flex">
                          <img src={visa} alt="VISA" />
                          <img src={mastercard} alt="MASTERCARD" />
                          <img src={mtn} alt="MTN MOMO" />
                          <img src={airtel} alt="AIRTEL MOMO" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sub-cta">
                    <a href="#reviews" className="button uppercase bold">
                      See Reviews
                    </a>
                  </div>
                </div>
              </div>
              <Slide top>
                <div className="image-side">
                  <div className="background"></div>
                  <img src={cover} alt="Dad Daughter Book" className="cover" />
                </div>
              </Slide>
            </div>
            <div className="hero-media">
              <div className="carousel">
                <div className="slider-items-container hero-review-slide">
                  <Slider {...heroReviewsCarousel}>
                    <ReviewSlideCard
                      revName="Brad Odilo"
                      revText="As a parent, I could connect with the wisdom packed in
                          each lesson and what I would desire that my child
                          grasps."
                    />
                    <ReviewSlideCard
                      revName="Bruno Mukisa"
                      revText="The Dad Daughter book is a conversation every father should bear a desire to hold with his daughter."
                    />
                    <ReviewSlideCard
                      revName="Baguma Naswiif"
                      revText="On a personal opinion, this is the best book I have ever read on parenting and grooming a girl child."
                    />
                    <ReviewSlideCard
                      revName="Tuckis Allan"
                      revText="I take dad daughter book as 200 letters I have to write for my daughter for her to even read in my demise"
                    />
                    <ReviewSlideCard
                      revName="Abdallah Musa"
                      revText="I usually spend most of my time away from home but when I come back, I sit with my daughter and we go through lessons from Dad Daughter book. It is really a great book that my daughter loves it so much"
                    />
                    <ReviewSlideCard
                      revName="Yiga Shakibu"
                      revText="It is sweet that I have a daughter amongst my children. It is sweeter having found a book that will help me tell exactly what I want her to know. I use The Dad Daughter book often as a reference when there is something I want my daughter to know."
                    />
                    <ReviewSlideCard
                      revName="Alexis Johns"
                      revText="I love my daughter so much and I believe this is one of the best gifts I will ever give her. I love how the dad daughter book has made it even more easier to spark deeper conversations with my daughter. This dad daughter book here, every parent or girl child should have a copy"
                    />
                  </Slider>
                </div>
              </div>
              <div className="section-video">
                <LiteYouTubeEmbed
                  id="RlRThBl1vYA"
                  playlist={false}
                  title="A sweet video for Daughters and Dads with Daughters"
                />
                <div className="video-title">
                  <p className="center rich-small">
                    A sweet video for Daughters and Dads with Daughters
                  </p>
                </div>
              </div>
            </div>
            <div className="section-footer center width-300 margin-auto">
              <div className="wft section-spacing-40 no-padding-bottom">
                <img
                  src={wft}
                  alt="Word For Today Publications"
                  className="wft-img"
                />
              </div>
              <p className="rich-small center uppercase">
                books by malidad.com are published, marketed and distributed by
                word for today publications limited{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeHero;
