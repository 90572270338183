import React from "react";
import { Slide } from "react-reveal";
import CheckOutFormPremiumDaughter from "../reusables/CheckOutFormPremiumDaughter";

const BuyPremiumDaughterBundleBody = () => {
  return (
    <section className="section-spacing-100">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <Slide top>
              <div className="section-head center margin-auto">
                <h3 className="modal-title uppercase">
                  MALIDAD BOOKS Premium Daughter Bundle{" "}
                  <p className="light-blue">
                    {" "}
                    (Dad daughter + the lifetime treasure) books
                  </p>
                </h3>

                <p className=" margin-top-10 dark">
                  Ordering 50+ copies of Dad Daughter book alone? Click{" "}
                  <a
                    href="https://form.jotform.com/booksmalidadcom/bulk-order-form-dad-daughter-book"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Here
                  </a>
                </p>
              </div>
            </Slide>
            <div className="section-body section-spacing-50 no-padding-bottom">
              <div className=" width-600 margin-auto padding-8 threads-bg light-blue-bg border-radius-2 box-shadow">
                <CheckOutFormPremiumDaughter />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BuyPremiumDaughterBundleBody;
