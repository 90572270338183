import React from "react";
import { Slide } from "react-reveal";
import BookOutCard from "../reusables/BookOutCard";
import tlt from "../../assets/images/content/tlt.png";
import ds from "../../assets/images/content/ds.png";

const OtherBooks = () => {
  return (
    <section className="section-spacing-100 no-padding-top" id="other">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <Slide top>
              <div className="section-head center width-300 margin-auto">
                <h3 className="section-title title">
                  other Books by{" "}
                  <span className="light-yellow">MALIDAD.COM</span>
                </h3>
              </div>
            </Slide>
            <div className="section-body section-spacing-40 no-padding-bottom">
              <div className="outbooks-cards-container">
                <BookOutCard
                  cover={tlt}
                  cardClass="btreasure"
                  bookTitle="The Lifetime Treasure"
                  bookSubtitle="Learn and Master The 18 Skills That Pay Off Forever But School Won't Teach You"
                  bookLink="https://thelifetimetreasure.com"
                  cardCtaClass="lyellow"
                />
                <BookOutCard
                  cover={ds}
                  cardClass="bdaddaughter"
                  bookTitle="Dad Son"
                  bookSubtitle="200 Life Lessons Every Father Must Teach His Son"
                  bookLink="https://dadsonbook.com"
                  cardCtaClass="lyellow"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OtherBooks;
