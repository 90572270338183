import React from "react";
import ddebook from "../../assets/images/bundle/ddebook.jpg";
import dsebook from "../../assets/images/bundle/dsebook.jpg";
import tltebook from "../../assets/images/bundle/tltebook.jpg";

const PremiumBundle = () => {
  return (
    <div className="bundle-card premium">
      <h4 className="bundle-title bordered-bottom-4 white uppercase center margin-top-bottom-10">
        Premium{" "}
      </h4>
      <div className="bundle-inner">
        <div className="bundle-images-container flex">
          <figure>
            <img src={ddebook} alt="Dad Daughter book by MALIDAD.COM" />
            <figcaption>Dad Daughter</figcaption>
          </figure>
          <figure>
            <img src={dsebook} alt="Dad Son book by MALIDAD.COM" />
            <figcaption>Dad Son</figcaption>
          </figure>
        </div>
        <div className="bundle-cta-container center section-spacing-20 no-padding-bottom">
          <p className="bundle-price bold">Price: 100,000 UGX | $29.99 USD</p>
          <a
            className="bundle-cta site-main-cta button light-yellow-button dark uppercase bold"
            href="/buy-premium-bundle"
            target="_blank"
            rel="noopener noreferrer"
          >
            BUY PREMIUM BUNDLE
          </a>
        </div>
      </div>
    </div>
  );
};

export default PremiumBundle;
