import React from "react";
import AboutBook from "../components/home/AboutBook";
import BookBundles from "../components/home/BookBundles";
import Contacts from "../components/home/Contacts";
import Faqs from "../components/home/Faqs";
import HomeHero from "../components/home/HomeHero";
import OtherBooks from "../components/home/OtherBooks";
import Reviews from "../components/home/Reviews";
import Social from "../components/home/Social";
const Home = () => {
  return (
    <>
      <HomeHero />
      <BookBundles/>
      {/* <WhoFor /> */}
      <Faqs />
      <AboutBook />
      <Reviews />
      <OtherBooks />
      <Contacts />
      <Social />
      {/*
      <ScrollTop />*/}
    </>
  );
};

export default Home;
