import React from "react";
import { Slide } from "react-reveal";
import malbie from "../../assets/images/reviewers/malbie.jpg";
import brad from "../../assets/images/reviewers/brad.png";
import vince from "../../assets/images/reviewers/vince.jpg";
import ReviewerCard from "../reusables/ReviewerCard";

const Reviews = () => {
  return (
    <section className=" section-spacing-100" id="reviews">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <Slide top>
              <div className="section-head center width-300 margin-auto">
                <h3 className="section-title title">
                  <span className=" light-yellow">Dad Daughter Book</span>{" "}
                  Reviews
                </h3>
              </div>
            </Slide>
            <div className="section-body section-spacing-50">
              <div className="reviews-cards-container relative carousel">
                <div className="background-bar dark-bg"></div>
                <div className="margin-auto">
                  <div className="reviews-cards-inner slider-items-container center-slide-arrows">
                    <ReviewerCard
                      revImg={brad}
                      revName="Brad Odilo"
                      revBio="Writer, Author, Father, Systems Thinker"
                      brief="The wisdom in the lessons in the Dad Daughter book is what I desire my child grasps"
                    >
                      <p>
                        As a parent, I could connect with the wisdom packed in
                        each lesson and what I would desire that my child
                        grasps.
                      </p>
                      <p className=" margin-top-10">
                        As a writer, the plainness and sincerity of the tone
                        used to communicate Dad’s heart to his Daughter,
                        impressed me — there is a need for communication to be
                        simple, poignant and direct.{" "}
                      </p>
                      <p className=" margin-top-10">
                        There is even a greater need for the student to excel
                        the tutor, in the same light that a Dad desires for his
                        Daughter to become better than him in handling life. And
                        the latter, is really what is imprinted in the pages of
                        this book.
                      </p>
                    </ReviewerCard>
                    <ReviewerCard
                      revImg={vince}
                      revName="Vince Kakooza"
                      revBio="Journalist, Dad"
                      brief="Grooming daughters is easier and better than fixing wives"
                    >
                      <p>
                        In this modern age, where everything moves so quickly
                        and daily life pressures often lead to social and family
                        dislocation, our daughters are extremely vulnerable to
                        falling through the cracks. A dad has to be focused,
                        disciplined and committed to giving his daughter(s) the
                        care and good nurturing that will see them prosper as
                        they grow into mature girls who fit naturally into
                        society.
                      </p>
                      <p className=" margin-top-10">
                        It requires a preparedness to sacrifice some good old
                        blood, sweat and, at times, a few tears. But it is worth
                        it to see your daughter become a part of the answer to
                        life’s problems, and not part of the problem itself.
                      </p>
                      <p className=" margin-top-10">
                        The Dad Daughter book is an easy-to-read guide providing
                        invaluable advice on ways to go about the challenge and
                        have plenty of fun on the journey. Because grooming
                        daughters is easier and better than fixing wives, I
                        recommend this book to every dad who wishes to do the
                        best for his daughter, you will be glad you read it or
                        gifted your daughter a copy.
                      </p>
                    </ReviewerCard>
                    <ReviewerCard
                      revImg={malbie}
                      revName="Bruno Mukisa"
                      revBio="Architect, Poet"
                      brief="The Dad Daughter book is a conversation every father should bear a desire to hold with his daughter."
                    >
                      <p>
                        The Dad Daughter book is a conversation every father
                        should bear a desire to hold with his daughter in which
                        he strives to instill in her the values she will be
                        proud of in her pursuit to make the best of her
                        existence.
                      </p>
                      <p className=" margin-top-10">
                        From the core values of life, to the fundamentals of
                        productive relationships, to the key considerations in
                        effective decision making, the dad daughter book
                        addresses all these wholesomely and gives a glimpse of
                        what difference guidance and informed choices can make.
                      </p>
                      <p className=" margin-top-10">
                        Each lesson is a nugget of wisdom and presents a
                        challenge to the conventional mindsets of modern
                        culture. It is these rich, informative and
                        transformative nuggets of knowledge that offer any girl
                        a chance to realize that this realm is navigable.
                      </p>
                    </ReviewerCard>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reviews;
