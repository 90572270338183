import React from "react";
import CheckOutForm from "./CheckOutForm";

const BuyModalContents = () => {
  return (
    <div className="modal-contents section-spacing-16 no-padding-bottom threads-bg">
      <div className="modal-head center">
        <h3 className="modal-title uppercase">
          Buy dad daughter book copy(ies):
        </h3>
        <p className=" margin-top-10 dark">
          Ordering 50+ copies? Click{" "}
          <a
            href="https://form.jotform.com/booksmalidadcom/bulk-order-form-dad-daughter-book"
            target="_blank"
            rel="noopener noreferrer"
          >
            Here
          </a>
        </p>
      </div>
      <div className="modal-body left section-spacing-40">
        <CheckOutForm />
      </div>
      {/*<div className="modal-footer center">
        <a
          target="_blank"
          href="https://forms.gle/vZEzqVdaf5xdcDMK7"
          className=" inherit-link bold font-size-18 dark uppercase"
        >
          Contact Us Here
        </a>
      </div>*/}
    </div>
  );
};

export default BuyModalContents;

{
  /*
<ul className="distribution-list flex">
          <li className="distribution-card">
            <h3 className="distribution-title">
              Want to buy more than 10 copies? <br /> (Hard Copy)
            </h3>
            <div className="distribution-cta-container">
              <a
                target="_blank"
                href="https://forms.gle/ZUaDf4eu6nFyVs316"
                className="distribution-cta block center bold"
                title="Bulk Buy Dad Daughter Book Hard Copies"
              >
                Click Here
              </a>
            </div>
          </li>
          <li className="distribution-card">
            <h3 className="distribution-title">
              From the US? <br /> (Hard Copy)
            </h3>
            <div className="distribution-cta-container">
              <a
                target="_blank"
                href="https://forms.gle/BQ5K6pSpXmrdBYxW9"
                className="distribution-cta block center bold"
                title="Buy Dad Daughter Book Hard Copy in US"
              >
                Click Here
              </a>
            </div>
          </li>
          <li className="distribution-card">
            <h3 className="distribution-title">
              From the UK? <br /> (Hard Copy)
            </h3>
            <div className="distribution-cta-container">
              <a
                target="_blank"
                href="https://forms.gle/yYiCnux5NXjvUnLo9"
                className="distribution-cta block center bold"
                title="Buy Dad Daughter Book Hard Copy in UK"
              >
                Click Here
              </a>
            </div>
          </li>
          <li className="distribution-card">
            <h3 className="distribution-title">
              From Uganda? <br /> (Hard Copy)
            </h3>
            <div className="distribution-cta-container">
              <a
                target="_blank"
                href="https://forms.gle/Byy7L6gk5a5D7V2Z9"
                className="distribution-cta block center bold"
                title="Buy Dad Daughter Book Hard Copy in Uganda"
              >
                Click Here
              </a>
            </div>
          </li>
          <li className="distribution-card">
            <h3 className="distribution-title">
              From East Africa? <br /> (Hard Copy)
            </h3>
            <div className="distribution-cta-container">
              <a
                target="_blank"
                href="https://forms.gle/ciLYkXh2ZuUsnn1dA"
                className="distribution-cta block center bold"
                title="Buy Dad Daughter Book Hard Copy in East Africa"
              >
                Click Here
              </a>
            </div>
          </li>
          <li className="distribution-card">
            <h3 className="distribution-title">
              From West Africa? <br /> (Hard Copy)
            </h3>
            <div className="distribution-cta-container">
              <a
                target="_blank"
                href="https://forms.gle/tW3HUqhmZ1uMJh2C8"
                className="distribution-cta block center bold"
                title="Buy Dad Daughter Book Hard Copy in West Africa"
              >
                Click Here
              </a>
            </div>
          </li>
          <li className="distribution-card">
            <h3 className="distribution-title">
              From Southern Africa? <br /> (Hard Copy)
            </h3>
            <div className="distribution-cta-container">
              <a
                target="_blank"
                href="https://forms.gle/oAystTuHuh2vVfWF7"
                className="distribution-cta block center bold"
                title="Buy Dad Daughter Book Hard Copy in Southern Africa"
              >
                Click Here
              </a>
            </div>
          </li>
          <li className="distribution-card">
            <h3 className="distribution-title">
              From North Africa? <br /> (Hard Copy)
            </h3>
            <div className="distribution-cta-container">
              <a
                target="_blank"
                href="https://forms.gle/WnSSexFKp358RidHA"
                className="distribution-cta block center bold"
                title="Buy Dad Daughter Book Hard Copy in North Africa"
              >
                Click Here
              </a>
            </div>
          </li>

          <li className="distribution-card">
            <h3 className="distribution-title">
              From India? <br /> (Hard Copy)
            </h3>
            <div className="distribution-cta-container">
              <a
                target="_blank"
                href="https://forms.gle/uaEdsNfGSQB2aLdd9"
                className="distribution-cta block center bold"
                title="Buy Dad Daughter Book Hard Copy in India"
              >
                Click Here
              </a>
            </div>
          </li>

          <li className="distribution-card">
            <h3 className="distribution-title">
              From Europe? <br /> (Hard Copy)
            </h3>
            <div className="distribution-cta-container">
              <a
                target="_blank"
                href="https://forms.gle/SQQZqmQL4L1WcYcj6"
                className="distribution-cta block center bold"
                title="Buy Dad Daughter Book Hard Copy in Europe"
              >
                Click Here
              </a>
            </div>
          </li>
          <li className="distribution-card">
            <h3 className="distribution-title">
              On Amazon.com <br />
              (Kindle Version) (Coming Soon)
            </h3>
            <div className="distribution-cta-container">
              <a
                target="_blank"
                href="#"
                className="distribution-cta block center bold"
                title="Buy Dad Daughter Book on Amazon"
              >
                Click Here
              </a>
            </div>
          </li>
          <li className="distribution-card">
            <h3 className="distribution-title">
              On Kobo <br /> (Ebook Version) (Coming Soon)
            </h3>
            <div className="distribution-cta-container">
              <a
                target="_blank"
                href="#"
                className="distribution-cta block center bold"
                title="Buy Dad Daughter Book on Kobo"
              >
                Click Here
              </a>
            </div>
          </li>
        </ul>

*/
}
